import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import FeatureBox from '../../../components/box-image/layout-one'
import { SectionWrap } from './features-area.style'

const FeaturesArea = (props) => {
    const featuredDataQuery = useStaticQuery(graphql`
        query infotechnoFeatureQueryData {
            homeTechSection(id: {eq: "feature-content"}) {
                title
                subtitle
              }
              allServices {
                edges {
                  node {
                    id
                    title
                    excerpt
                    icon {
                      img
                    }
                  }
                }
              }
        }
    `);
    const featureSecData = featuredDataQuery.homeTechSection;
    const featureData = featuredDataQuery.allServices.edges;
    const { featureBoxStyles } = props;
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            subtitle={featureSecData.subtitle}
                            title={featureSecData.title}
                        />
                    </Col>
                </Row>
                <Row>
                    {featureData.map(feature => (
                        <Col lg={4} md={6} key={feature.node.id}>
                            <FeatureBox
                                {...featureBoxStyles}
                                title={feature.node.title}
                                imageSrc={feature.node.icon.img}
                                desc={feature.node.excerpt}
                                path={`/services/${feature.node.id}`}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </SectionWrap>
    )
}

FeaturesArea.propTypes = {
    featureBoxStyles: PropTypes.object,
    headingStyle: PropTypes.object,
    linkStyle: PropTypes.object
}

FeaturesArea.defaultProps = {
    featureBoxStyles: {
        mt: '60px',
        responsive: {
            small: {
                mt: '47px'
            }
        }
    },
    headingStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333',
        mt: '60px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    }
}

export default FeaturesArea;