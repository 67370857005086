import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import { HeroWrapper, HeroTextBox } from './hero-area.style'

const HeroArea = (props) => {
    const heroDataQuery = useStaticQuery(graphql`
        query heroQueryData {
            homeTechSection(id: {eq: "hero-content"}) {
                title
                subtitle
                shortDesc
                path
                bg_image
            }
        }
    `);
    const { title, subtitle, shortDesc, path, bg_image } = heroDataQuery.homeTechSection;
    const { subtitleStyle, titleStyle, textStyle, btnStyle } = props;

    return (
        <Fragment>
            <HeroWrapper src={bg_image}>
                <Container>
                    <Row alignitems="center">
                        <Col lg={8} md={7}>
                            <HeroTextBox>
                                {subtitle && <Heading {...subtitleStyle}>{subtitle}</Heading>}
                                {title && <Heading {...titleStyle}>{parse(title)}</Heading>}
                                {shortDesc && <Text {...textStyle}>{shortDesc}</Text>}
                                {path && <Button {...btnStyle} to="/contact-us">Contact Us</Button>}
                            </HeroTextBox>
                        </Col>
                    </Row>
                </Container>
            </HeroWrapper>
        </Fragment>
    )
}

HeroArea.propTypes = {
    subtitleStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    textStyle: PropTypes.object,
    btnStyle: PropTypes.object
}

HeroArea.defaultProps = {
    subtitleStyle: {
            as: "h6",
            lineHeight: '36px',
            letterspacing: '3px',
            fontWeight: 700,
            color: '#ffffff',
            texttransform: 'uppercase',
            mb: '20px',
            responsive: {
                xsmall: {
                    fontSize: '16px',
                    letterspacing: '1px',
                }
            }
        },
        titleStyle: {
            as: "h2",
            color: '#ffffff',
            fontweight: 500,
            fontSize: '58px',
            letterspacing: 0,
            lineHeight: 1.2,
            mb: '15px',
            child: {
                color: 'secondary'
            },
            responsive: {
                xlarge: {
                    fontSize: '60px'
                },
                large: {
                    fontSize: '46px'
                },
                medium: {
                    fontSize: '40px'
                },
                small: {
                    fontSize: '34px'
                },
                xsmall: {
                    fontSize: '28px'
                }
            }
        },
        textStyle: {
            color: '#ffffff',
            lineHeight: '32px',
            fontweight: 500,
            fontSize: '18px',
            responsive: {
                xsmall: {
                    fontSize: '16px',
                    lineHeight: '26px'
                }
            }
        },
        btnStyle: {
            bgcolor: '#e64b2e',
            hover: '2',
            mt: '15px'
        },
}

export default HeroArea;