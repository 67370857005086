import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import Hero from '../containers/index-infotechno/hero'
import ContactArea from '../containers/global/contact-area/contact-three'
import SectionThree from '../containers/elements/client-logo/section-three'
import FeaturesArea from '../containers/index-infotechno/features-area'
import AboutArea from '../containers/index-infotechno/about-area'
import CaseStudyArea from '../containers/index-infotechno/case-study-area'

const IndexInfoTechno = ({ location }) => {
    return (
        <Layout location={location}>
            <SEO/>
            <Header />
            <main className="site-wrapper-reveal">
                <Hero />
                <SectionThree/>              
                <FeaturesArea />
                <AboutArea />
                <CaseStudyArea />
                <ContactArea />
            </main>
            <Footer />
        </Layout>
    )
}

export default IndexInfoTechno;