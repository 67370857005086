import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import CaseStudyBox from '../../../components/box-large-image/layout-two'
import SwiperSlider from '../../../components/ui/swiper'
import { SectionWrap } from './case-study-area.style'

const CaseStudyArea = ({
    sectionTitleStyle,
    caseStudyStyles,
    slider,
    sliderStyle }) => {
    const caseStudyData = useStaticQuery(graphql`
    query InfoTechCaseStudyQuery {
        homeTechSection(id: {eq: "case-study-content"}) {
            title
            subtitle
            bottom_text
            bottom_link_text
            bottom_link
            bgImage
          }
        allPortfolios(filter: {is_featured: {eq: true}}, limit: 3) {
            edges {
              node {
                id
                title
                category
                excerpt
                image
              }
            }
        }
      } 
    `);
    const caseStudySecData = caseStudyData.homeTechSection;
    const portfolios = caseStudyData.allPortfolios.edges;
    const imageData = caseStudySecData.bgImage;

    return (
        <SectionWrap src={imageData}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            title={caseStudySecData.title}
                            subtitle={caseStudySecData.subtitle}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider {...sliderStyle} settings={slider}>
                            {portfolios.map(port => (
                                <div className="item" key={port.node.id}>
                                    <CaseStudyBox
                                        {...caseStudyStyles}
                                        imageSrc={port.node.image}
                                        title={port.node.title}
                                        desc={port.node.excerpt}
                                        path={`/portfolio/${port.node.id}`}
                                        btnText="View Details"
                                    />
                                </div>
                            ))}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

CaseStudyArea.propTypes = {
    sectionStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    bottomTextStyle: PropTypes.object,
    bottoTextLinkStyle: PropTypes.object
}

CaseStudyArea.defaultProps = {
    sectionTitleStyle: {
        mb: '40px',
        responsive: {
            small: {
                mb: '27px'
            }
        }
    },
    bottomTextStyle: {
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333',
        mt: '60px',
        align: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    bottoTextLinkStyle: {
        fontWeight: 500,
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    slider: {
        slidesPerView: 3,
        loop: false,
        pagination: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            }
        }
    },
    sliderStyle: {
        pagination: {
            bottom: 0
        }
    },
    caseStudyStyles: {
        boxStyle: {
            mt: '20px',
            mb: '35px',
            ml: '15px',
            mr: '15px'
        },
        contentBoxStyle: {
            pt: '25px',
            pl: '26px',
            pr: '26px',
            textalign: 'left'
        },
        headingStyle: {
            as: 'h6',
            fontweight: 600,
            mb: '2px'
        },
        descStyle: {
            mb: 0,
            mt: '13px'
        }
    }
}

export default CaseStudyArea;