import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {SectionWrap} from './section.style'
import SwiperSlider from '../../../../components/ui/swiper'
import ClientLogo from '../../../../components/ui/client-logo'
import {Container, Row, Col} from '../../../../components/ui/wrapper'
import SectionTitle from '../../../../components/ui/section-title'


const Section = (props) => {
    const ExpertisesQueryData = useStaticQuery(graphql`
        query ExpertisesDataQuery{
            allHomeBrandSection(sort: {order: ASC, fields: numSort}) {
                edges {
                  node {
                    id
                    path
                    image
                    hover_image
                  }
                }
            }
        }
    `);
    const expertisesData = ExpertisesQueryData.allHomeBrandSection.edges;
    const { slider, sliderStyle } = props
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            subtitle="OUR EXPERTISES"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider settings={slider} {...sliderStyle}>
                            {expertisesData.map((data, i) => {
                                return(
                                    <div key={i} className="item">
                                        <ClientLogo brandImage={data.node.hover_image} layout={3}/>
                                    </div>
                                )
                            })}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

Section.propTypes = {
    slider: PropTypes.object
}
 
Section.defaultProps = {
    slider: {
        slidesPerView: 6,
        loop: false,
        speed: 1000,
        pagination: false,
        spaceBetween: 30,
        autoplay: {
            delay: 2500
        },
        breakpoints: {
            320: {
                slidesPerView : 2
            },
            575:{
                slidesPerView : 3
            },
            767:{
                slidesPerView : 4
            },
            991:{
                slidesPerView : 5
            },            
            1499:{
                slidesPerView : 6
            }
        }
    },
    sliderStyle: {
        align: 'center'
    }
}


export default Section;