import styled from 'styled-components'
import {device} from '../../../theme'

export const SectionWrap = styled.div `
    width: 100%;
    height: auto;
    background-image: url(${props => props.src});
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 700px;
    @media ${device.medium}{
        padding-top: '80px';
        padding-bottom: '80px';
    }
    @media ${device.small}{
        padding-top: '60px';
        padding-bottom: '60px';
    }
`;